import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

import img1 from "../../img/safari.jpg";
import img2 from "../../img/safari1.jfif";
import img3 from "../../img/safari11.jfif";
import img4 from "../../img/safari4.jfif";
import img5 from "../../img/safari2.jfif";
import img6 from "../../img/safari3.jfif";

import "./style.css";

const Gallery = () => {
  const { t } = useTranslation();

  const onClick = (e) => {
    e.preventDefault();
  };

  return (
    <section className="gauto-gallery-area section_70">
      <Container>
        <Row>
          <Col lg={4} sm={6}>
            <div className="single-gallery">
              <div className="img-holder">
                <img src={img1} alt="gallery 1" style={{height:"300px"}}/>
                <div className="overlay-content">
                  <div className="inner-content">
                    <div className="title-box">
                      <h3>
                        <Link to="/" onClick={onClick}>
                          Beautiful Design
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={6}>
            <div className="single-gallery">
              <div className="img-holder">
                <img src={img2} alt="gallery 2"  style={{height:"300px"}}/>
                <div className="overlay-content">
                  <div className="inner-content">
                    <div className="title-box">
                      <h3>
                        <Link to="/" onClick={onClick}>
                          Large Space
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={6}>
            <div className="single-gallery">
              <div className="img-holder">
                <img src={img3} alt="gallery 3"  style={{height:"300px"}}/>
                <div className="overlay-content">
                  <div className="inner-content">
                    <div className="title-box">
                      <h3>
                        <Link to="/" onClick={onClick}>
                          Great Color Scheme
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={6}>
            <div className="single-gallery">
              <div className="img-holder">
                <img src={img6} alt="gallery 4"  style={{height:"300px"}}/>
                <div className="overlay-content">
                  <div className="inner-content">
                    <div className="title-box">
                      <h3>
                        <Link to="/" onClick={onClick}>
                         Comfortable Seating
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={6}>
            <div className="single-gallery">
              <div className="img-holder">
                <img src={img4} alt="gallery 5"  style={{height:"300px"}}/>
                <div className="overlay-content">
                  <div className="inner-content">
                    <div className="title-box">
                      <h3>
                        <Link to="/" onClick={onClick}>
                          Excellent Rim Design
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={6}>
            <div className="single-gallery">
              <div className="img-holder">
                <img src={img5} alt="gallery 6"  style={{height:"300px"}}/>
                <div className="overlay-content">
                  <div className="inner-content">
                    <div className="title-box">
                      <h3>
                        <Link to="/" onClick={onClick}>
                          Best choice for family
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="load-more">
              <Link to="/" onClick={onClick} className="gauto-btn">
                {t("btn_gallery")}
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Gallery;
