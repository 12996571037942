import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Col, Container, Row, Dropdown } from "react-bootstrap";
import {
  FaPhoneAlt,
  FaSignInAlt,
  FaUserAlt,
  FaSearch,
  FaGlobe,
} from "react-icons/fa";
import MobileMenu from "../../components/MobileMenu";

import Logo from "../../img/logo.png";
import globe from "../../img/globe.png";
import clock from "../../img/clock.png";
import "flag-icon-css/css/flag-icons.min.css";
import "./style.css";
import PSLogo from "../../img/pslogo.png";

const languages = [
  {
    code: "fr",
    name: "Français",
    country_code: "fr",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "pt",
    name: "Português",
    country_code: "pt",
  },
];

const Header = () => {
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const onClick = (e) => {
    e.preventDefault();
  };

  const { t } = useTranslation();

  return (
    <Fragment>
      <section className="gauto-header-top-area">
        <Container>
          <Row>
            <Col md={6}>
              <div className="header-top-left">
                <p>
                  {t("need_help")} <FaPhoneAlt /> {t("cell")}: 0320-4461140
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="header-top-right">
                <strong className="text-black">
                  Email : salman@psmotors.com{" "}
                </strong>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <header className="gauto-main-header-area">
        <Container>
          <Row style={{ marginBlock: "-34px" }} className="d-none d-lg-flex">
            <Col md={3} className="mb-4">
              <div className="site-logo" sty>
                <a href="/">
                  <img src={PSLogo} alt="gauto" className="hellox" style={{maxHeight:"60px",marginTop:"28px"}}/>
                </a>
              </div>
            </Col>
            <Col md={3} style={{ marginTop: "30px" }}>
              <div className="site-logo">
                <a href="/">
                  <img src={Logo} alt="gauto" className="hellox" />
                </a>
              </div>
            </Col>

            {/* <div className="col-lg-3">
              <div className="header-action">
                <Link to="/contact">
                  <FaPhoneAlt /> {t("request_call")}
                </Link>
              </div>
            </div> */}
          </Row>
          <Row className="d-block d-md-none" style={{ marginBlock: "-26px" }}>
            <Col md={3} className="mb-4">
              <div className="site-logo">
                <a href="/">
                  <img src={PSLogo} alt="gauto" className="hellox" />
                </a>
              </div>
            </Col>

            {/* <div className="col-lg-3">
              <div className="header-action">
                <Link to="/contact">
                  <FaPhoneAlt /> {t("request_call")}
                </Link>
              </div>
            </div> */}
          </Row>
        </Container>
      </header>
      <section className="gauto-mainmenu-area">
        <Container>
          <Row>
            <Col lg={9}>
              <div className="mainmenu">
                <nav>
                  <ul id="gauto_navigation">
                    <li>
                      <Link to="/">{t("header-navigation.home")}</Link>
                    </li>
                    <li>
                      <Link to="/about">{t("header-navigation.about")}</Link>
                    </li>
                    {/* <li>
                      <Link to="/" onClick={onClick}>
                        {t("header-navigation.cars")}
                      </Link>
                      <ul>
                        <li>
                          <Link to="/car-listing">
                            {t("header-navigation.car_listing")}
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            {t("header-navigation.car_booking")}
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    <li>
                      <Link to="/gallery">
                        {t("header-navigation.gallery")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/" onClick={onClick}>
                        {t("header-navigation.shop")}
                      </Link>
                      <ul>
                        <li>
                          <Link to="/product">
                            {t("header-navigation.product")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-single">
                            {t("header-navigation.product_details")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/cart">
                            {t("header-navigation.shopping_cart")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/checkout">
                            {t("header-navigation.checkout")}
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    {/* <li>
                      <Link to="/" onClick={onClick}>
                        {t("header-navigation.pages")}
                      </Link>
                      <ul>
                        <li>
                          <Link to="/blog">{t("header-navigation.blog")}</Link>
                        </li>
                        <li>
                          <Link to="">
                            {t("header-navigation.blog_single")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/error">
                            {t("header-navigation.not_found")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/login">
                            {t("header-navigation.login")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/register">
                            {t("header-navigation.register")}
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    <li>
                      <Link to="/contact">
                        {t("header-navigation.contact")}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </Col>
            <Col lg={3} sm={12}>
              <div className="main-search-right">
                <MobileMenu />
                {/* <div className="header-cart-box">
                  <div className="login dropdown">
                    <Link to="/cart" className="cart-icon" id="dropdownMenu1">
                      <span></span>
                    </Link>
                  </div>
                </div> */}
                {/* <div className="search-box">
                  <form onSubmit={SubmitHandler}>
                    <input type="search" placeholder="Search" />
                    <button type="submit">
                      <FaSearch />
                    </button>
                  </form>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default Header;
