import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";

import "./style.css";

const Help = () => {
  const { t } = useTranslation();

  return (
    <section className="gauto-call-area">
      <Container>
        <Row>
          <Col md={12}>
            <div className="call-box">
              <div className="call-box-inner">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t("partner_location_text", {
                      partner_count: "<span>150</span>",
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></h2>
                <p>
                PS Motors Forland operates in an ethical and sustainable way and deals with its environmental and social impacts. We believe in careful consideration of human rights, the community and environment in which we operate. Today, corporate social responsibility has become one of the standard business practices. In order to give rise to sustainability we must pay attention to workplace and corporate culture of caring about essential social issues.
                </p>
                <div className="call-number">
                  <div className="call-icon">
                    <FaPhoneAlt />
                  </div>
                  <div className="call-text">
                    <p>{t("need_any_help")}</p>
                    <h4>
                      <a href="tel:0301-8250753">0301-8250753</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Help;
