import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaQuoteRight } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from "../../img/testimonial.jpg";
import img2 from "../../img/testimonial-2.jpg";

import "./style.css";

const Testimonial = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    arrows: false,
    speed: 1200,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="gauto-testimonial-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              <h4>{t("some_words")}</h4>
              <h2>{t("testimonial")}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Slider className="testimonial-slider" {...settings}>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "Excellent dealership! I recently purchased a car from them, and the
                      entire experience was fantastic. The staff was friendly, and the
                      process was smooth. I highly recommend them."
                    </p>
                    <div className="testimonial-meta">
                      <div className="client-info">
                        <h3>Abdul Basit</h3>
                        <p>Customer</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "I couldn't be happier with my car purchase from this dealership.
                      The team was knowledgeable, and they helped me find the perfect
                      vehicle. Great service all around!"
                    </p>
                    <div className="testimonial-meta">
                      <div className="client-image">
                        
                      </div>
                      <div className="client-info">
                        <h3>Ahmad Usman</h3>
                        <p>Customer</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "I've had multiple great experiences with this dealership. Their
                      customer service is top-notch, and they always go the extra mile to
                      ensure customer satisfaction. Highly recommended!"
                    </p>
                    <div className="testimonial-meta">
                      <div className="client-image">
                        
                      </div>
                      <div className="client-info">
                        <h3>Akhtar Saeed</h3>
                        <p>Customer</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p>
                      "I purchased my dream car from this dealership, and I couldn't be
                      happier. The quality of the cars they offer and their attention to
                      detail is impressive. Thank you!"
                    </p>
                    <div className="testimonial-meta">
                      <div className="client-image">
                        
                      </div>
                      <div className="client-info">
                        <h3>Abdullah Sattar</h3>
                        <p>Customer</p>
                      </div>
                    </div>
                    <FaQuoteRight />
                  </div>
                </div>
              </div>
            </Slider>

          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonial;
