import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

import img1 from "../../img/city-transport.png";
import img2 from "../../img/airport-transport.png";
import img3 from "../../img/hospital-transport.png";
import img4 from "../../img/wedding-ceremony.png";
import img5 from "../../img/hotel-transport.png";
import img6 from "../../img/luggege-transport.png";

import "./style.css";

const ServiceList = () => {
  const { t } = useTranslation();

  return (
    <section className="gauto-service-area service-page-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              <h4>{t("see_our")}</h4>
              <h2>{t("latest_service")}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className="single-service">
              <span className="service-number">1</span>
              <div className="service-icon">
                <i className="fa fa-car"></i>
              </div>
              <div className="service-text">
                <Link to="/service-single">
                  <h3><i className="fa fa-car"></i> {t("Premium Vehicle Dealership")}</h3>
                </Link>
                <p>
                  Our dealership offers a wide selection of high-quality vehicles, competitive pricing, and exceptional customer service to ensure a seamless and satisfying car-buying experience for you.
                </p>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="single-service">
              <span className="service-number">2 </span>
              <div className="service-icon">
                <img src={img3} alt="hospital trasport" />
              </div>
              <div className="service-text">
                <Link to="/service-single">
                  <h3>{t("display_center")}</h3>
                </Link>
                <p>
                  Risus commodo maecenas accumsan lacus vel facilisis. Dorem
                  ipsum dolor consectetur adipiscing elit.
                </p>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="single-service">
              <span className="service-number">3 </span>
              <div className="service-icon">
                <img src={img4} alt="wedding trasport" />
              </div>
              <div className="service-text">
                <Link to="/service-single">
                  <h3>{t("service_center")}</h3>
                </Link>
                <p>
                  Risus commodo maecenas accumsan lacus vel facilisis. Dorem
                  ipsum dolor consectetur adipiscing elit.
                </p>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="single-service">
              <span className="service-number">4 </span>
              <div className="service-icon">
                <img src={img5} alt="wedding trasport" />
              </div>
              <div className="service-text">
                <Link to="/service-single">
                  <h3>{t("speedy_process")}</h3>
                </Link>
                <p>
                  Risus commodo maecenas accumsan lacus vel facilisis. Dorem
                  ipsum dolor consectetur adipiscing elit.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ServiceList;
