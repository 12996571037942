import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";

import img1 from "../../img/safari.jpg";
import img2 from "../../img/drive.jpg";

import "./style.css";

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="about-page-area section_70">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="about-page-left">
                <h4>{t("about_page.subtitle")}</h4>
                <h3>{t("about_page.title")}</h3>
                <p>
                At PS Motors, our unwavering commitment to safety is at the core of everything we do. We understand that when you're on the road, nothing is more important than the assurance that you and your loved ones are secure. That's why we have dedicated ourselves to offering a selection of vehicles that are not only stylish and high-performing but also meet the highest standards of safety.
                </p>
                <p>
                From the moment you step into our showroom, you'll notice our attention to detail and our dedication to safety. Our knowledgeable staff is trained to focus on your needs, guiding you through our collection of vehicles that are equipped with the latest safety features and innovations. Whether you're looking for a family car, a robust SUV, or an efficient commuter vehicle, our range includes options that are meticulously designed to protect you on the road.
                </p>
                <p>
                At PS Motors, we don't just sell cars, we build relationships based on trust and the shared value of safety. We're proud to be a part of your journey on the road, providing you with solutions that make every ride a safe one. Visit us today and experience the peace of mind that comes with being part of the Forland family, where safety is not just a feature—it's a promise.
                </p>
                <div className="about-page-call">
                  <div className="page-call-icon">
                    <FaPhoneAlt />
                  </div>
                  <div className="call-info">
                    <p>{t("need_any_help")}</p>
                    <h4>
                      <a href="tel:12435424">0301-8250753</a>
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-page-right">
                <img src={img1} alt="about page" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="gauto-about-promo section_70">
        <Container>
          <Row>
            <Col md={12}>
              <div className="about-promo-text">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: t("about_page.proud_title", {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="about-promo-image">
                <img src={img2} alt="about promo" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutPage;
