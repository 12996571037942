import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from "../../img/city-transport.png";
import img2 from "../../img/airport-transport.png";
import img3 from "../../img/hospital-transport.png";
import img4 from "../../img/wedding-ceremony.png";
import img5 from "../../img/hotel-transport.png";
import img6 from "../../img/luggege-transport.png";

import "./style.css";

const Service = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    arrows: false,
    speed: 1200,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="gauto-service-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              <h4>{t("see_our")}</h4>
              <h2>{t("latest_service")}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Slider className="service-slider" {...settings}>
              <div className="slide">
                <div className="single-service">
                  <span className="service-number">1</span>
                  <div className="service-icon">
                    <i className="fa fa-car"></i>
                  </div>
                  <div className="service-text">
                    <Link to="">
                      <h3><i className="fa fa-car"></i> {t("Premium Vehicle Dealership")}</h3>
                    </Link>
                    <p>
                      Our dealership offers a wide selection of high-quality vehicles, competitive pricing, and exceptional customer service to ensure a seamless and satisfying car-buying experience for you.
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-service">
                  <span className="service-number">2</span>
                  <div className="service-icon">
                    <i className="fa fa-eye"></i>
                  </div>
                  <div className="service-text">
                    <Link to="">
                      <h3><i className="fa fa-eye"></i> {t("Interactive Display Center")}</h3>
                    </Link>
                    <p>
                    dynamic and engaging space designed to enhance the customer experience through interactive technology and innovative displays.
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-service">
                  <span className="service-number">3</span>
                  <div className="service-icon">
                    <i className="fa fa-wrench"></i>
                  </div>
                  <div className="service-text">
                    <Link to="">
                      <h3><i className="fa fa-wrench"></i> {t("Expert Service Center")}</h3>
                    </Link>
                    <p>
                    state-of-the-art facility dedicated to providing exceptional automotive care and maintenance. It's a place where vehicles are not just serviced but are given meticulous attention by a team of highly trained professionals. 
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-service">
                  <span className="service-number">4</span>
                  <div className="service-icon">
                    <i className="fa fa-rocket"></i>
                  </div>
                  <div className="service-text">
                    <Link to="">
                      <h3><i className="fa fa-rocket"></i> {t("Fast and Efficient Process")}</h3>
                    </Link>
                    <p>
                    The process is designed to minimize unnecessary steps or bottlenecks. Each stage of the process smoothly transitions to the next, ensuring that there are no delays or hold-ups.
                    </p>
                  </div>
                </div>
              </div>
            </Slider>

          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Service;
